<template>
	<b-card>
		<form @submit.prevent="saveCoupons">
			<b-col cols="12">
				<div class="form-group">
					<label>Kode Voucher*</label>
					<b-row>
						<b-col md="10">
							<validation-provider
								name="code"
								rules="required"
								v-slot="{ errors, classes }"
							>
								<input 
									type="text" 
									:class="classes"
									:state="errors.length > 0 ? false:null"
									class="form-control" 
									v-model="formCoupons.code"
								>
								<small
									v-for="(validation, index) in validations.code"
									:key="`errorName${index}`"
									class="text-danger"
								>{{ validation }}</small>
							</validation-provider>
						</b-col>
						<b-col md="2">
							<button 
								@click.prevent="checkCoupon"
								variant="primary"
								class="btn waves-effect waves-float waves-light btn-primary"
							>
								Check Code
							</button>
						</b-col>
					</b-row>
				</div>
				<div class="form-group">
					<label>Tipe Voucher*</label>
					<validation-provider
						name="Tipe Voucher"
						rules="required"
						v-slot="{ errors }"
					>
						<div class="demo-inline-spacing">
							<div class="custom-control custom-radio mt-0">
								<input 
									name="inlineRadioOptions1" 
									class="custom-control-input" 
									type="radio" 
									:state="errors.length > 0 ? false:null"
									id="radio1" 
									value="nominal" 
									v-model="formCoupons.amount_type"
								>
								<label 
									class="custom-control-label" 
									for="radio1"
								>
									Nominal
								</label>
							</div>
							<div class="custom-control custom-radio mt-0">
								<input 
									name="inlineRadioOptions1" 
									class="custom-control-input" 
									type="radio" 
									:state="errors.length > 0 ? false:null"
									id="radio2"
									value="percent"
									v-model="formCoupons.amount_type"
								>
								<label 
									class="custom-control-label" 
									for="radio2"
								>
									Percent
								</label>
							</div>
						</div>
						<small
							v-for="(validation, index) in validations.amount_type"
							:key="`errorName${index}`"
							class="text-danger"
						>{{ validation }}</small>
					</validation-provider>
				</div>
				<div class="form-group" v-if="formCoupons.amount_type === 'percent'">
					<label>Batas Potongan*</label>
					<input 
						type="number" 
						class="form-control" 
						v-model="formCoupons.maximum_discount"
					>
				</div>
				<div class="form-group">
					<label>Tipe Kupon*</label>
					<validation-provider
						name="Tipe Kupon"
						rules="required"
						v-slot="{ errors }"
					>
						<div class="demo-inline-spacing">
							<div class="custom-control custom-radio mt-0">
								<input 
									name="inlineRadioOptions2" 
									class="custom-control-input" 
									type="radio" 
									id="radio3" 
									value="code" 
									:state="errors.length > 0 ? false:null"
									v-model="formCoupons.type"
								>
								<label 
									class="custom-control-label" 
									for="radio3"
								>
									Code
								</label>
							</div>
							<div class="custom-control custom-radio mt-0">
								<input 
									name="inlineRadioOptions2" 
									class="custom-control-input" 
									type="radio" 
									id="radio4"
									value="coupon"
									:state="errors.length > 0 ? false:null"
									v-model="formCoupons.type"
								>
								<label 
									class="custom-control-label" 
									for="radio4"
								>
									Coupon
								</label>
							</div>
						</div>
						<small
							v-for="(validation, index) in validations.type"
							:key="`errorName${index}`"
							class="text-danger"
						>{{ validation }}</small>
					</validation-provider>
				</div>
				<div class="form-group">
					<label v-if="formCoupons.amount_type == 'percent'">Diskon(%)*</label>
					<label v-else>Nominal Diskon*</label>
					<input 
						type="number" 
						class="form-control" 
						v-model="formCoupons.amount"
					>
				</div>
				<div class="form-row">
					<div class="form-group col-md-6">
						<label>Tanggal Berlaku:*</label>
						<validation-provider
							name="Tanggal Berlaku"
							rules="required"
							v-slot="{ errors }"
						>
							<flat-pickr
								v-model="formCoupons.start_from"
								class="form-control"
								:state="errors.length > 0 ? false:null"
								:config="{ enableTime: true,dateFormat: 'Y-m-d H:i:ss'}"
							/>
							<small
								v-for="(validation, index) in validations.start_from"
								:key="`errorName${index}`"
								class="text-danger"
							>{{ validation }}</small>
						</validation-provider>
					</div>
					<div class="form-group col-md-6">
						<label>Tanggal Berakhir:*</label>
						<validation-provider
							name="Tanggal Berlaku"
							rules="required"
							v-slot="{ errors }"
						>
							<flat-pickr
								v-model="formCoupons.expire_at"
								class="form-control"
								:state="errors.length > 0 ? false:null"
								:config="{ enableTime: true,dateFormat: 'Y-m-d H:i:ss'}"
							/>
							<small
								v-for="(validation, index) in validations.expire_at"
								:key="`errorName${index}`"
								class="text-danger"
							>{{ validation }}</small>
						</validation-provider>
					</div>
				</div>
				<div class="form-group">
					<label>Jumlah Kupon*</label>
					<validation-provider
						name="Jumlah Kupon"
						rules="required"
						v-slot="{ errors, classes }"
					>
						<input 
							type="number" 
							class="form-control"
							:class="classes"
							:state="errors.length > 0 ? false:null"
							v-model="formCoupons.qty"
						>
						<small
							v-for="(validation, index) in validations.qty"
							:key="`errorName${index}`"
							class="text-danger"
						>{{ validation }}</small>
					</validation-provider>
				</div>
				<div class="form-group">
					<label>Jenis Penggunaan Voucher*</label>
						<div class="demo-inline-spacing">
							<div class="custom-control custom-radio mt-0">
								<input 
									name="inlineRadioOptions3" 
									class="custom-control-input" 
									type="radio" 
									id="radio5" 
									value="single" 
									v-model="formCoupons.redeem_type"
								>
								<label 
									class="custom-control-label" 
									for="radio5"
								>
									Single
								</label>
							</div>
							<div class="custom-control custom-radio mt-0">
								<input 
									name="inlineRadioOptions3" 
									class="custom-control-input" 
									type="radio" 
									id="radio6"
									value="multiple"
									v-model="formCoupons.redeem_type"
								>
								<label 
									class="custom-control-label" 
									for="radio6"
								>
									Multiple
								</label>
							</div>
						</div>
						
				</div>
				<template v-if="formCoupons.redeem_type == 'multiple'">
					<div class="form-group">
						<label>Maksimal Penggunaan Multiple Voucher*</label>
						<input 
							type="number" 
							class="form-control" 
							v-model="formCoupons.redeem_limit"
						>
					</div>
				</template>
				<div class="form-group">
					<label>Produk*</label>
					<v-select
						v-model="productsSelected"
						:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
						label="name"
						multiple
						placeholder="Cari dan tambahkan produk di sini"
						:options="products"
						@input="getProducts(null)"
						@keyup.stop.native="multipleProduct"
					/>
					<small>(Kosongkan jika berlaku untuk semua produk)</small>
				</div>
				<div class="form-group">
					<label>Konsultasi*</label>
					<v-select
						v-model="consultanSelected"
						:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
						label="ios_id"
						multiple
						placeholder="-- Pilih Konsultasi --"
						:options="consultation"
					/>
				</div>
				<div class="form-group">
					<label>Service *</label>
					<v-select
						v-model="servicesSelected"
						:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
						label="name"
						multiple
						placeholder="-- Pilih Service --"
						:options="services"
					/>
				</div>
			</b-col>
			<b-col cols="12" class="p-0 mt-2">
				<button
					variant="primary"
					class="btn waves-effect waves-float waves-light btn-primary"
				>
					Save
				</button>
				&nbsp;
				&nbsp;
				<a
					@click="handleBackButton()"
					variant="secondary"
					class="btn waves-effect waves-float waves-light btn-secondary"
				>
					Cancel
				</a>
			</b-col>
		</form>
	</b-card>
</template>

<script>
import {
	BCard, 
	BFormGroup, 
	BFormRadio, 
	BRow, 
	BCol,
	BFormCheckbox
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, configure } from 'vee-validate'
import { required } from '@validations'
import { successNotification, errorNotification } from '@/auth/utils'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import _ from 'lodash'
configure({
	classes: {
		valid: 'is-valid',
		invalid: 'is-invalid',
		dirty: ['is-dirty', 'is-dirty'], // multiple classes per flag!
		// ...
	}
})
export default {
	components: {
		BCard,
		BRow,
		BCol,
		BFormGroup,
		BFormRadio,
		BFormCheckbox,
		flatPickr,
		vSelect,
		ValidationProvider,
		ValidationObserver
	},

	setup() {
		return {
			successNotification, 
			errorNotification
		}
	},

	data() {
		return {
			required,
			formCoupons:{
				code: '',
				type: '',
				amount_type: '',
				maximum_discount: 0,
				type: '',
				amount: '',
				start_from: null,
				expire_at: null,
				qty: '',
				redeem_type: '',
				redeem_limit: 0,
				products: [],
				consultan_prices: [],
				services:[],
			},
			products: [],
			consultation: [],
			services:[],
			productsSelected: [],
			consultanSelected: [],
			servicesSelected: [],
			validations: ''
		}
	},

	mounted() {
		this.loadCoupons()
	},

	methods: {
		handleBackButton() {
			history.back()
		},
		multipleProduct: _.debounce(function (e) {
			const keyword = e.target.value
			this.getProducts(keyword)
		}, 500),

		getProducts(keyword) {
			this.products = []
			this.$http.get('admin/products', {
				params: {
					'keyword': keyword != null ? keyword : null
				}
			})
			.then(response => {
				response.data.data.data.forEach(element => {
					this.products.push({
						'icon':element.icon,
						'image':element.image,
						'name':element.name ,
						'uuid':element.uuid 
					})
				});
			})
		},

		getConsultant() {
			this.$http.get('admin/consultan-prices')
				.then(response => {
				this.consultation = response.data.data.data
			})
		},
		
		getServices() {
			this.$http.get('admin/services')
			.then(response => {
				this.services = response.data.data
			})
		},
		async loadCoupons() {
			const params = this.$route.params.uuid
			await this.$http.get('admin/coupons/' + params)
				.then(response => {
					this.formCoupons = response.data.data
					this.consultanSelected = this.formCoupons.consultan_prices
					this.productsSelected = this.formCoupons.products
					this.servicesSelected = this.formCoupons.services
					console.log(this.servicesSeleted, 'ini service');
				})
		},
		checkCoupon() {
			const payload = new FormData()
			payload.append("code", this.formCoupons.code)
			this.$http.post('admin/coupons/check-code' , payload)
			.then(response => {
				if (response.data.data.available === true) {
					successNotification(this, 'Success', 'Code bisa dipakai!')
				} else {
					errorNotification(this, 'Oops!', 'Code Sudah ada!')
				}
			})
		},
		saveCoupons() {
			let productUuid = []
			let consultanUuid = []
			let serviceUuid = []
			this.productsSelected.forEach((product)=> productUuid.push(product.uuid))
			this.formCoupons.products = productUuid
			this.consultanSelected.forEach((consultan)=> consultanUuid.push(consultan.uuid))
			this.formCoupons.consultan_prices = consultanUuid
			this.servicesSelected.forEach((service)=> serviceUuid.push(service.uuid))
			this.formCoupons.services = serviceUuid

			if (this.formCoupons.redeem_type === "single") {
				this.formCoupons.redeem_limit = 0
			} 
			if (this.formCoupons.amount_type === 'nominal') {
				this.formCoupons.maximum_discount = 0
			}
			const params = this.$route.params.uuid
			this.$http.patch('admin/coupons/' + params, this.formCoupons)
			.then(response => {
				successNotification(this, 'Success', 'Coupon berhasil diedit!')
				this.$router.push({ name: 'coupon-list' })
			}).catch(error => {
				if (error.response.data.meta.validations) {
					this.validations = error.response.data.meta.validations
					errorNotification(this, 'Kode Kupon', this.validations.code.join(' '))
					errorNotification(this, 'Tipe Voucher', this.validations.amount_type.join(' '))
					errorNotification(this, 'Tipe Kupon', this.validations.type.join(' '))
					errorNotification(this, 'Tanggal Berlaku', this.validations.start_from.join(' '))
					errorNotification(this, 'Tanggal Berakhir', this.validations.expire_at.join(' '))
					errorNotification(this, 'Jumlah Kupon', this.validations.qty.join(' '))
					errorNotification(this, 'Jenis Voucher', this.validations.redeem_type.join(' '))
				}
			})
		}
	},

	async created() {
		await this.getProducts(),
		await this.getConsultant(),
		await this.getServices()
	},

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
	.form-check-inline {
		flex-wrap: wrap;
	}
	.custom-checkbox.custom-control {
		padding-left: 1.8rem;
		margin-right: 1.8rem;
		margin-bottom: 1rem;
	}
</style>